<template>
  <div class="inline-flex align-items-center" :class="getCls()">
    <div
      :class="[namePos === 'top' ? 'mb-s2' : 'mr-s2', { 'text-muted': !isChecked }]"
      v-if="name && namePos !== 'right'"
    >
      {{ name }}
    </div>
    <div class="flex align-items-center">
      <span class="mr-s2" v-if="labelOff && labelPosOut"> {{ labelOff }}</span>
      <SwitchInput
        v-model="isChecked"
        @change="handleChange"
        :label-on="labelPosIn ? labelOn : ''"
        :label-off="labelPosIn ? labelOff : ''"
        :class="'switch-' + color"
      />
      <span class="ml-s2" v-if="labelOn && labelPosOut"> {{ labelOn }}</span>
      <span class="ml-s2 text-sm" :class="{ 'text-muted': !isChecked }" v-if="namePos === 'right'">
        {{ name }}</span
      >
    </div>
  </div>
</template>

<script>
import SwitchInput from './SwitchInput';
export default {
  name: 'Switch',
  components: { SwitchInput },
  props: {
    name: String,
    labelOn: String,
    labelOff: String,
    labelPosOut: { type: Boolean, default: false },
    labelPosIn: { type: Boolean, default: false },
    namePos: String,
    color: { type: String, default: 'light' },
    size: String,
    checked: { type: Boolean, default: false },
  },
  data() {
    return {
      isChecked: false,
    };
  },
  methods: {
    initSwitch() {
      this.isChecked = this.checked;
    },
    handleChange() {
      this.$emit('change', this.isChecked);
    },
    getCls() {
      let cls = 'switch-' + this.color;
      cls += this.namePos === 'top' ? 'flex-column' : '';
      cls += this.size ? ' switch-' + this.size : '';
      return cls;
    },
  },
  mounted() {
    this.initSwitch();
  },
};
</script>

<style scoped></style>
