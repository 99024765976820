<template>
  <div :id="buttonId" class="copy-button-wrapper">
    <Button
      class="button-copy"
      @click="copyToClipboard"
      :class="[color ? `text-${color}` : '', { hoverable: !isCopied }]"
    >
      <Icon name="copy" size="20"> </Icon>
    </Button>
    <div
      class="tag-floating tag-copied fadeout animation-duration-3000 animation-iteration-1"
      v-if="isCopied"
    >
      Copied
    </div>
    <div class="tag-floating tag-copy" v-if="!isTouch">Copy</div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { UniqueComponentId } from 'primevue/utils';
import Icon from '@/components/icon/Icon';
import { useI18n } from 'vue-i18n';
import { useNotifications } from '@/composables/notifications/useNotifications';

const props = defineProps({
  data: String,
  color: String,
});

const { t } = useI18n();
const buttonId = UniqueComponentId();
const isTouch = computed(() => 'ontouchstart' in window || navigator.maxTouchPoints);
const { showErrorNotification } = useNotifications();
const isCopied = ref(false);
const tagHide = () => (isCopied.value = false);

const successCopyToClipboard = () => {
  isCopied.value = true;
  setTimeout(tagHide, 3500);
};

const fallback = () => {
  if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const input = document.createElement('input');
    input.style.position = 'absolute';
    input.style.right = '9999px';
    input.setAttribute('readonly', 'true');
    document.getElementById(buttonId).appendChild(input);
    input.value = props.data;
    input.focus();
    input.select();
    try {
      successCopyToClipboard();
      return document.execCommand('copy');
    } catch (ex) {
      showErrorNotification(`${t('error.copyFailed')} ${ex}`, 'common');
    } finally {
      document.getElementById(buttonId).removeChild(input);
    }
  }
};
const copyToClipboard = () => {
  if (!navigator.clipboard) {
    fallback();
    return;
  }
  navigator.clipboard
    .writeText(props.data)
    .then(() => {
      successCopyToClipboard();
    })
    .catch(() => {
      fallback();
    });
};
</script>

<style scoped lang="scss">
.copy-button-wrapper {
  position: relative;
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}
.button-copy {
  @extend .button-clear;
}
.tag-copy {
  display: none;
}
.tag-copy,
.tag-copied {
  font-size: $font-size-sm !important;
  color: var(--text-muted) !important;
  line-height: 1 !important;
  box-sizing: border-box;
}
.button-copy.hoverable:hover + .tag-copy {
  display: block;
}
.button-copy + .tag-copied {
  opacity: 0;
}
</style>
