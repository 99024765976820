<template>
  <div class="wallet-panel-body clickable-list">
    <WalletConnector
      v-for="(connector, id, i) in getWalletList()"
      :key="i"
      :connector="connector"
      class="list-item"
      @click="onWalletLogin(connector)"
    >
    </WalletConnector>
  </div>
</template>

<script>
import WalletConnector from './WalletConnector.vue';
import connectors from '@/helpers/connectors.json';
import { mapActions } from 'vuex';
import { WALLET_ACTION_TYPES } from '@/store/modules/wallet/wallet.module';
import { MODULE_NAMES } from '@/store';

export default {
  name: 'WalletConnectorList',
  components: {
    WalletConnector,
  },
  methods: {
    ...mapActions(MODULE_NAMES.WALLET, {
      walletLogin: WALLET_ACTION_TYPES.WALLET_LOGIN,
    }),
    getWalletList() {
      const wallets = [];
      Object.keys(connectors).forEach(item => {
        wallets.push(connectors[item]);
      });
      return wallets;
    },
    async onWalletLogin(connector) {
      try {
        await this.walletLogin(connector.id);
        this.$emit('onSelectWallet');
      } catch (error) {
        this.$emit('noWallet', connector);
      }
    },
  },
};
</script>

<style scoped></style>
