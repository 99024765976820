import {
  MAX_SLIPPAGE_TOLERANCE,
  MIN_SLIPPAGE_TOLERANCE,
  MAX_TRANSACTION_DEADLINE,
  MIN_TRANSACTION_DEADLINE,
} from '@/sdk/constants';

const validateTransactionSettingsMixin = {
  methods: {
    validateTransactionDeadline(value: string) {
      if (+value >= MAX_TRANSACTION_DEADLINE) {
        value = MAX_TRANSACTION_DEADLINE.toString();
      }
      if (+value <= MIN_TRANSACTION_DEADLINE) {
        value = MIN_TRANSACTION_DEADLINE.toString();
      }
      return value;
    },
    validateSlippageTolerance(value: string) {
      if (+value <= MIN_SLIPPAGE_TOLERANCE) {
        value = MIN_SLIPPAGE_TOLERANCE.toString();
      }
      if (+value >= MAX_SLIPPAGE_TOLERANCE) {
        value = MAX_SLIPPAGE_TOLERANCE.toString();
      }
      return value;
    },
  },
};

export default validateTransactionSettingsMixin;
