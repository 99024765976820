import notificationsMixin from '@/mixins/notifications.mixin';
import { useToast } from 'primevue/usetoast';

export function useNotifications() {
  const $toast = useToast();

  const notifications = notificationsMixin.methods!;

  return {
    showInProgressNotification: notifications.showInProgressNotification.bind({ $toast }),
    showSuccessNotification: notifications.showSuccessNotification.bind({ $toast }),
    showErrorNotification: notifications.showErrorNotification.bind({ $toast }),
    closeNotifications: notifications.closeNotifications.bind({ $toast }),
  };
}
