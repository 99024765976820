import { defineStore } from 'pinia';
import { computed, reactive, ref, unref, watch } from 'vue';
import { ISwapForm } from '@/store/modules/swap/models/swap-form.interface';
import { Token } from '@/sdk/entities/token';
import { SWAP_FORM_INPUTS } from '@/store/modules/swap/constants/SWAP_FORM_INPUTS';
import { ISwapFormInfo } from '@/store/modules/swap/models/swap-form-info.interface';
import { SLIPPAGE_TOLERANCE, TRANSACTION_DEADLINE } from '@/helpers/constants';
import { ITransactionSetting } from '@/store/modules/swap/models/transaction-setting.interface';
import {
  TransactionStatusResponse,
  TransactionStatusError,
  exactInRoutes,
  exactOutRoutes,
  fetchSwapTransactionStatus,
} from '@/helpers/cross-chain-api';
import { fromWei, getScanLink } from '@/sdk/utils';
import { safeDateNowPlusEstimatedMinutes, safeParseUnits } from '@/helpers/utils';
import { ISwapBestTrade } from '@/store/modules/swap/models/swap-best-trade.interface';
import { ChainId } from '@/sdk/constants';
import { useTokens } from '@/store/modules/tokens/useTokens';
import BigNumber from 'bignumber.js';
import _ from 'lodash';
import {
  getErc20Contract,
  getRouterContract,
  getWethContract,
  transactionWithEstimatedGas,
} from '@/helpers/contract.helper';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { getRouterAddress } from '@/helpers/address.helper';
import { useStore } from 'vuex';
import { useNotifications } from '@/store/modules/notifications/useNotifications';
import {
  INotification,
  INotificationStep,
} from '@/store/modules/notifications/models/notification.interface';
import { useI18n } from 'vue-i18n';
import { ethers } from 'ethers';
import { DEFAULT_NETWORK_ID } from '@/helpers/networkParams.helper';
import { CROSSCHAIN_STEPS_NUMBER } from '@/store/modules/swap/constants/SWAP_NOTIFICATION';
import { SUPPORTED_NETWORK_MODE } from '@/constants/SUPPORTED_NETWORK_MODE';

export const useSwap = defineStore('swap', () => {
  const { state } = useStore();
  const { t } = useI18n();

  const fireSwapFormRequest = ref<boolean>(false);
  const hasBestTradeAnswer = ref<boolean>(false);
  const swapFormUpdateRequest = ref<Promise<any>>();

  const swapForm = reactive<ISwapForm>({
    formLoading: false,
    formInfoLoading: false,
    input: {
      token: null,
      amount: null,
      estimated: false,
    },
    output: {
      token: null,
      amount: null,
      estimated: false,
    },
    estimatingInProgress: false,
    bestTradeRequestInProgress: false,
    bestTradeUpdateRequestInProgress: false,
    bestTrade: {} as ISwapBestTrade,
    settings: {
      slippageTolerance: SLIPPAGE_TOLERANCE.toString(),
      transactionDeadline: TRANSACTION_DEADLINE.toString(),
      enableMultiHops: true,
      localSwapPriority: true,
    },
    hasAllowance: false,
    allowanceRequestInProgress: false,
    errors: [],
    isShowSwapInfo: false,
  });

  const { addNotification } = useNotifications();

  const getApproveNotificationOptions = (status: string, explorer?: string): INotification => {
    return {
      id: `approve_${swapForm.input.token?.symbol}`,
      status: status,
      content: t(`swap.notificationContent.approve.${status}`, {
        token: swapForm.input.token?.symbol,
      }),
      life: status !== 'inProgress',
      explorerLink: explorer,
    };
  };
  const getSwapNotificationOptions = (options: {
    status: 'inProgress' | 'success' | 'error';
    id: string;
    cSwapForm: ISwapForm;
    hideExplorerLink?: boolean;
    txHash?: string;
    step?: INotificationStep;
  }): INotification => {
    return {
      ...options,
      content: t(`swap.notificationContent.swap.${options.status}`, {
        input: options.cSwapForm.input.token?.symbol,
        output: options.cSwapForm.output.token?.symbol,
      }),
      //life: options.status !== 'inProgress',
      explorerLink: options.hideExplorerLink
        ? undefined
        : getScanLink(
            options.txHash ? options.txHash : options.id,
            'transaction',
            options.step?.explorerChainId ? options.step?.explorerChainId : options.step?.chainId,
          ),
    };
  };

  const $reset = (noResetTokens?: boolean): void => {
    if (!noResetTokens) {
      swapForm.input.token = null;
      swapForm.output.token = null;
    }
    swapForm.input.amount = null;
    swapForm.input.estimated = false;
    swapForm.output.amount = null;
    swapForm.output.estimated = false;
    swapForm.estimatingInProgress = false;
    swapForm.bestTrade = {} as ISwapBestTrade;
    swapForm.bestTradeRequestInProgress = false;
    swapForm.bestTradeUpdateRequestInProgress = false;
    swapForm.hasAllowance = false;
    swapForm.allowanceRequestInProgress = false;
    swapForm.errors = [];
    swapForm.isShowSwapInfo = false;
  };

  const setSettings = (settings: ITransactionSetting): void => {
    swapForm.settings = Object.assign(swapForm.settings, settings);
    fireSwapFormRequest.value = true;
  };

  const setEstimated = (field: SWAP_FORM_INPUTS): void => {
    swapForm[field].estimated = false;
    swapForm[getReverseField(field)].estimated = true;
  };

  const updateAmount = (amount: string | null, field: SWAP_FORM_INPUTS): void => {
    swapForm[field].amount = amount;
  };

  const setToken = (token: Token, field: SWAP_FORM_INPUTS): void => {
    if ((swapForm[field].token?.decimals || 18) > token.decimals) {
      const bnAmount = new BigNumber(swapForm[field]?.amount || 0).toFixed(token.decimals);
      updateAmount((+bnAmount).toString(), field);
    }
    swapForm[field].token = token;
    fireSwapFormRequest.value = true;
  };

  const setAmount = (amount: string, field: SWAP_FORM_INPUTS): void => {
    const isAmountIsNotEmpty = amount.length > 0 || +amount !== 0;
    if (isAmountIsNotEmpty) {
      swapForm[field].amount = amount;
      setEstimated(field);
      fireSwapFormRequest.value = true;
    } else {
      $reset(true);
    }
  };

  const prepareSwapInfoRequest = (): void => {
    swapFormUpdateRequest.value = undefined;
    if (swapForm.output.estimated) {
      swapFormUpdateRequest.value = exactInRoutes(
        ...prepareDataForExactInOutRequest(swapForm, 'IN'),
      );
    } else if (swapForm.input.estimated) {
      swapFormUpdateRequest.value = exactOutRoutes(
        ...prepareDataForExactInOutRequest(swapForm, 'OUT'),
      );
    }
  };

  const updateAmountFromSwapInfoResponse = (response: ISwapBestTrade): void => {
    if (response.amountOut) {
      updateAmount(
        fromWei(response.amountOut, swapForm.output.token?.decimals).toString(),
        SWAP_FORM_INPUTS.INPUT_B,
      );
    } else if (response.amountIn) {
      updateAmount(
        fromWei(response.amountIn, swapForm.input.token?.decimals).toString(),
        SWAP_FORM_INPUTS.INPUT_A,
      );
    }
  };

  const isCrossChainRoute = (response: ISwapBestTrade): boolean => {
    return +response.route.crossChainPortfolioIndex > -1;
  };

  const onSwapFormChange = async (manualUpdate?: boolean) => {
    swapForm.formInfoLoading = true; // TODO check
    swapForm.estimatingInProgress = true;
    swapForm.bestTradeRequestInProgress = true;
    swapForm.bestTrade = {} as ISwapBestTrade;
    swapForm.errors = [];

    let result;
    if (!manualUpdate) {
      prepareSwapInfoRequest();
    }

    try {
      result = await swapFormUpdateRequest.value;
      updateAmountFromSwapInfoResponse(result);
      if (swapForm.input.estimated && isCrossChainRoute(result)) {
        setEstimated(SWAP_FORM_INPUTS.INPUT_A);
      }

      swapForm.bestTrade = Object.assign({}, result);
      console.log('swapForm.bestTrade', swapForm.bestTrade);
    } catch (e) {
      console.log('ERROR', e);
      swapForm.errors.push('noRoute');
      swapForm.bestTrade = {} as ISwapBestTrade;
      if (swapForm.input.estimated) swapForm.input.amount = null;
      if (swapForm.output.estimated) swapForm.output.amount = null;
    } finally {
      swapForm.formInfoLoading = false; // TODO check
      swapForm.estimatingInProgress = false;
      swapForm.bestTradeRequestInProgress = false;
      if (
        (swapForm.input.estimated && swapForm.output.amount === null) ||
        (swapForm.output.estimated && swapForm.input.amount === null)
      ) {
        $reset(true);
      }
    }
  };

  const doUpdateRequestByTimer = async () => {
    console.groupCollapsed('doUpdateRequestByTimer');
    console.log('bestTradeRequestInProgress', swapForm.bestTradeRequestInProgress);
    console.log('swapFormUpdateRequest.value', swapFormUpdateRequest.value);
    console.groupEnd();
    if (swapForm.bestTradeRequestInProgress) return;
    if (swapFormUpdateRequest.value === undefined) return;
    try {
      const result = await swapFormUpdateRequest.value;
      updateAmountFromSwapInfoResponse(result);
      swapForm.bestTrade = Object.assign({}, result);
      console.log('update by timer swapForm.bestTrade', swapForm.bestTrade);
    } catch (e) {
      console.log('auto update error', e);
    }
  };

  const checkAllowance = async () => {
    console.log('checkAllowance', swapForm.input.token);
    if (!state.wallet.isInjected) return false;
    if (!swapForm.input.token || !swapForm.input.amount) return false;
    if (swapForm.input.token.isETHToken()) {
      swapForm.hasAllowance = true;
      return true;
    }
    try {
      const allowanceResult = await checkERC20Allowance(
        swapForm.input.token.address,
        state.wallet.account,
      );
      console.log('allowanceResult', allowanceResult);
      const allowanceAmount = fromWei(
        allowanceResult.toString(),
        swapForm.input.token?.decimals,
      ).toString();
      console.log(
        'allowanceAmount',
        allowanceAmount,
        swapForm.input.amount,
        +allowanceAmount >= +swapForm.input.amount,
      );
      swapForm.hasAllowance = +allowanceAmount >= +swapForm.input.amount;
    } catch (e) {
      swapForm.hasAllowance = false;
      throw Error(e);
    }
  };

  const setAllowance = async () => {
    console.log('setAllowance');
    if (!swapForm.input.token) return false;
    if (!swapForm.input.amount) return false;
    if (!state.wallet.isInjected) return false;

    swapForm.allowanceRequestInProgress = true;
    try {
      const tokenContract = getErc20Contract(
        swapForm.input.token.address,
        getInstance()?.web3?.getSigner(),
      );
      const result = await transactionWithEstimatedGas(tokenContract, 'approve', [
        getRouterAddress(),
        safeParseUnits(swapForm.input.amount, swapForm.input.token.decimals).toString(),
      ]);
      const explorerLink = getScanLink(result.hash, 'transaction');
      addNotification(getApproveNotificationOptions('inProgress', explorerLink));
      await result.wait();
      swapForm.hasAllowance = true;
      addNotification(getApproveNotificationOptions('success', explorerLink));
    } catch (e) {
      addNotification(getApproveNotificationOptions('error'));
      throw Error(e);
    } finally {
      swapForm.allowanceRequestInProgress = false;
    }
  };

  const onFormChange = async (manualUpdate?: boolean) => {
    if (checkIfSwapFormHasNoErrors(swapForm)) {
      await checkAllowance();
      await onSwapFormChange(manualUpdate);
    } else {
      swapFormUpdateRequest.value = undefined;
      swapForm.bestTrade = {} as ISwapBestTrade;
    }
  };

  const switchInputs = async () => {
    [swapForm.input.token, swapForm.output.token] = [swapForm.output.token, swapForm.input.token];
    [swapForm.input.estimated, swapForm.output.estimated] = [
      swapForm.output.estimated,
      swapForm.input.estimated,
    ];
    [swapForm.input.amount, swapForm.output.amount] = [
      swapForm.input.estimated ? null : swapForm.output.amount,
      swapForm.output.estimated ? null : swapForm.input.amount,
    ];
    swapForm.hasAllowance = false;
    fireSwapFormRequest.value = true;
  };

  watch(
    () => fireSwapFormRequest.value,
    async val => {
      if (unref(val)) {
        await onFormChange();
      }
      fireSwapFormRequest.value = false;
    },
  );

  watch(
    () => swapForm.input.amount,
    async val => {
      if (val) {
        await checkAllowance();
      } else {
        swapForm.hasAllowance = false;
      }
    },
  );

  const doSwap = async () => {
    console.log('doSwap');
    const cSwapForm: ISwapForm = _.cloneDeep(swapForm);
    const account: string = state.wallet.account;
    const isCrossChainSwap = +cSwapForm.bestTrade.route.crossChainPortfolioIndex !== -1;
    let id = '';
    let resultCrossChain: TransactionStatusResponse | undefined;
    let step: INotificationStep = {
      current: 1,
      total: CROSSCHAIN_STEPS_NUMBER,
      chainId: cSwapForm.input.token?.chainId,
    };

    try {
      const transactionResponse = await createSwapTransaction(cSwapForm, account);
      id = transactionResponse.hash;
      addNotification(
        getSwapNotificationOptions({
          id,
          status: 'inProgress',
          cSwapForm,
          step: isCrossChainSwap ? { ...step } : undefined,
        }),
      );

      $reset();

      await transactionResponse.wait();

      addNotification(
        getSwapNotificationOptions({
          id,
          status: 'success',
          cSwapForm,
          step: isCrossChainSwap ? { ...step } : undefined,
        }),
      );

      if (isCrossChainSwap) {
        step = Object.assign(step, {
          current: 2,
          chainId:
            SUPPORTED_NETWORK_MODE === 'TESTNET'
              ? ChainId.BLUES_CHAIN_TESTNET
              : ChainId.BLUES_CHAIN_MAINNET,
          explorerChainId: cSwapForm.output.token?.chainId,
        });
        addNotification(
          getSwapNotificationOptions({
            id,
            status: 'inProgress',
            hideExplorerLink: true,
            cSwapForm,
            step: { ...step },
          }),
        );

        resultCrossChain = await fetchSwapTransactionStatus(
          DEFAULT_NETWORK_ID as unknown as ChainId,
          id,
        );
        console.log('resultCrossChain', resultCrossChain);
        addNotification(
          getSwapNotificationOptions({
            id,
            txHash: resultCrossChain.transactionStatus.txHashDestination,
            status: 'success',
            cSwapForm,
            step: { ...step },
          }),
        );
      }
    } catch (error) {
      console.log(error);
      if (error instanceof TransactionStatusError) {
        resultCrossChain = error.cause;
      }
      addNotification(
        getSwapNotificationOptions({
          id,
          txHash:
            isCrossChainSwap && resultCrossChain
              ? resultCrossChain.transactionStatus.txHashDestination
              : undefined,
          status: 'error',
          hideExplorerLink:
            isCrossChainSwap && resultCrossChain?.transactionStatus?.txHashDestination === '',
          cSwapForm,
          step: isCrossChainSwap ? { ...step } : undefined,
        }),
      );
    }
  };

  return {
    swapForm,
    setToken,
    setAmount,
    // getSwapInfo: computed(() => getSwapInfoFromBestTrade(swapForm)),
    swapSettings: computed(() => swapForm.settings),
    hasBestTradeAnswer: computed(() => hasBestTradeAnswer.value),
    setSettings,
    onFormChange,
    setAllowance,
    doSwap,
    $reset,
    switchInputs,
    doUpdateRequestByTimer,
  };
});

function getReverseField(field: SWAP_FORM_INPUTS) {
  return field === SWAP_FORM_INPUTS.INPUT_A ? SWAP_FORM_INPUTS.INPUT_B : SWAP_FORM_INPUTS.INPUT_A;
}

async function checkERC20Allowance(address: string, account: string): Promise<any> {
  console.log('checkERC20Allowance', address);
  const tokenContract = getErc20Contract(address, getInstance()?.web3?.getSigner());
  console.log('tokenContract', tokenContract);
  return await tokenContract.allowance(account, getRouterAddress());
}

function validatePositiveNumber(value: string): boolean {
  try {
    const bnValue = new BigNumber(value);
    if (bnValue.gt(0)) return true;
  } catch (e) {
    return false;
  }
  return false;
}

function checkIfSwapFormHasNoErrors(swapForm: ISwapForm): boolean {
  console.log('checkIfSwapFormHasNoErrors', swapForm);
  swapForm.errors = [];
  if (swapForm.input.token && swapForm.output.token) {
    if (swapForm.input.token.isSameSymbol(swapForm.output.token)) {
      swapForm.errors.push('sameTokens');
      return false;
    }
  }
  if (!swapForm.input.token) return false;
  if (!swapForm.output.token) return false;
  if (!swapForm.input.estimated && !swapForm.output.estimated) return false;
  if (
    swapForm.input.estimated &&
    swapForm.output.amount &&
    !validatePositiveNumber(swapForm.output.amount)
  )
    return false;
  if (
    swapForm.output.estimated &&
    swapForm.input.amount &&
    !validatePositiveNumber(swapForm.input.amount)
  )
    return false;
  return true;
}

function prepareDataForExactInOutRequest(
  swapForm: ISwapForm,
  requestType: 'IN' | 'OUT',
): [ChainId, string, ChainId, string, string, 1 | 2 | 3, boolean] {
  return [
    swapForm.input.token!.chainId,
    swapForm.input.token!.address,
    swapForm.output.token!.chainId,
    swapForm.output.token!.address,
    requestType === 'IN'
      ? safeParseUnits(swapForm.input.amount, swapForm.input.token!.decimals)
      : safeParseUnits(swapForm.output.amount, swapForm.output.token!.decimals),
    swapForm.settings.enableMultiHops ? 3 : 1,
    !!swapForm.settings.localSwapPriority,
  ];
}

export function getSwapInfoFromBestTrade(swapForm: ISwapForm): ISwapFormInfo {
  const { getTokenByAddressAndChainId } = useTokens();
  if (Object.keys(swapForm.bestTrade).length === 0) return {} as ISwapFormInfo;
  return <ISwapFormInfo>{
    crossChainFee:
      +swapForm.bestTrade.route.crossChainPortfolioIndex !== -1
        ? +fromWei(swapForm.bestTrade.crossChainFee, swapForm.input.token?.decimals).toString()
        : undefined,
    lpFee: +fromWei(swapForm.bestTrade.lpFee, swapForm.input.token?.decimals).toString(),
    priceImpact: +fromWei(swapForm.bestTrade.priceImpact, 2).toString(),
    price: +new BigNumber(swapForm.output.amount || 1).div(swapForm.input.amount || 1).toString(),
    priceInverse: +new BigNumber(swapForm.input.amount || 1)
      .div(swapForm.output.amount || 1)
      .toString(),
    minimumReceived: swapForm.bestTrade.amountOut
      ? fromWei(swapForm.bestTrade.amountOut, swapForm.output.token?.decimals)
          .div(new BigNumber(swapForm.settings.slippageTolerance).div(100).plus(1))
          .toString()
      : undefined,
    maximumSold: swapForm.bestTrade.amountIn
      ? fromWei(swapForm.bestTrade.amountIn, swapForm.input.token?.decimals)
          .div(new BigNumber(swapForm.settings.slippageTolerance).div(100).plus(1))
          .toString()
      : undefined,
    route: {
      path: swapForm.bestTrade.route.path.map((address, index) => {
        const chainId: ChainId = swapForm.bestTrade.route.chains[index] as unknown as ChainId;
        return { token: getTokenByAddressAndChainId(address, chainId), chainId: chainId };
      }),
    },
  };
}

async function createSwapTransaction(
  swapForm: ISwapForm,
  account: string,
): Promise<ethers.providers.TransactionResponse> {
  const routerContract: ethers.Contract = getRouterContract(
    getRouterAddress(),
    getInstance().web3.getSigner(),
  );

  if (isSwapETHForWETH(swapForm)) {
    if (swapForm.output.token) {
      const wethContract = getWethContract(
        swapForm.output.token?.address,
        getInstance().web3.getSigner(),
      );
      return swapETHForWETH(swapForm, wethContract);
    }
  }

  if (isSwapWETHForETH(swapForm)) {
    if (swapForm.input.token) {
      const wethContract = getWethContract(
        swapForm.input.token?.address,
        getInstance().web3.getSigner(),
      );
      return swapWETHForETH(swapForm, wethContract);
    }
  }

  if (swapForm.output.estimated) {
    console.log('estimated', swapForm.input.token);
    if (swapForm.input.token?.isETHToken()) {
      console.log('swapExactETHForTokensWithPortfolios');
      return swapExactETHForTokensWithPortfolios(swapForm, account, routerContract);
    } else if (swapForm.output.token?.isETHToken()) {
      console.log('swapExactTokensForETHWithPortfolios');
      return swapExactTokensForETHWithPortfolios(swapForm, account, routerContract);
    } else {
      console.log('swapExactTokensForTokensWithPortfolios');
      return swapExactTokensForTokensWithPortfolios(swapForm, account, routerContract);
    }
  } else if (swapForm.input.estimated) {
    if (swapForm.input.token?.isETHToken()) {
      return swapETHForExactTokensWithPortfolios(swapForm, account, routerContract);
    } else if (swapForm.output.token?.isETHToken()) {
      return swapTokensForExactETHWithPortfolios(swapForm, account, routerContract);
    } else {
      return swapTokensForExactTokensWithPortfolios(swapForm, account, routerContract);
    }
  }

  return swapExactTokensForTokensWithPortfolios(swapForm, account, routerContract);
}

function isSwapETHForWETH(swapForm: ISwapForm): boolean {
  if (!swapForm.input.token || !swapForm.output.token) return false;
  return swapForm.input.token?.isETHToken() && swapForm.output.token?.isWETHToken();
}

function isSwapWETHForETH(swapForm: ISwapForm): boolean {
  if (!swapForm.input.token || !swapForm.output.token) return false;
  return swapForm.input.token?.isWETHToken() && swapForm.output.token?.isETHToken();
}

async function swapETHForWETH(
  swapForm: ISwapForm,
  wethContract: ethers.Contract,
): Promise<ethers.providers.TransactionResponse> {
  const inputAmount = safeParseUnits(
    swapForm.input.amount,
    swapForm.input.token?.decimals,
  ).toString();
  const overrides = {
    value: inputAmount,
  };
  return transactionWithEstimatedGas(wethContract, 'deposit', [], overrides);
}

async function swapWETHForETH(
  swapForm: ISwapForm,
  wethContract: ethers.Contract,
): Promise<ethers.providers.TransactionResponse> {
  const inputAmount = safeParseUnits(
    swapForm.input.amount,
    swapForm.input.token?.decimals,
  ).toString();

  return transactionWithEstimatedGas(wethContract, 'withdraw', [inputAmount]);
}

async function swapExactETHForTokensWithPortfolios(
  swapForm: ISwapForm,
  account: string,
  routerContract: ethers.Contract,
): Promise<ethers.providers.TransactionResponse> {
  const inputAmount = safeParseUnits(
    swapForm.input.amount,
    swapForm.input.token?.decimals,
  ).toString();
  const minAmountOut = safeParseUnits(
    new BigNumber(swapForm.output.amount || 1)
      .div(new BigNumber(swapForm.settings.slippageTolerance).div(100).plus(1))
      .toString(),
    swapForm.output.token?.decimals,
  );
  const overrides = {
    value: inputAmount,
  };

  console.log('swapExactETHForTokensWithPortfolios:params: ', {
    amountOutMin: minAmountOut,
    path: swapForm.bestTrade.route.path,
    to: account,
    deadline: safeDateNowPlusEstimatedMinutes(+swapForm.settings.transactionDeadline),
    portfolios: swapForm.bestTrade.route.portfolios,
    destinationChain:
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.output.token?.chainId,
    firstXChainPortfolioNum:
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.bestTrade.route.crossChainPortfolioIndex,
    overrides: overrides,
  });

  return transactionWithEstimatedGas(
    routerContract,
    'swapExactETHForTokensWithPortfolios',
    [
      minAmountOut,
      swapForm.bestTrade.route.path,
      account,
      safeDateNowPlusEstimatedMinutes(+swapForm.settings.transactionDeadline),
      swapForm.bestTrade.route.portfolios,
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.output.token?.chainId,
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.bestTrade.route.crossChainPortfolioIndex,
    ],
    overrides,
  );
}

async function swapExactTokensForETHWithPortfolios(
  swapForm: ISwapForm,
  account: string,
  routerContract: ethers.Contract,
): Promise<ethers.providers.TransactionResponse> {
  const inputAmount = safeParseUnits(
    swapForm.input.amount,
    swapForm.input.token?.decimals,
  ).toString();
  const minAmountOut = safeParseUnits(
    new BigNumber(swapForm.output.amount || 1)
      .div(new BigNumber(swapForm.settings.slippageTolerance).div(100).plus(1))
      .toString(),
    swapForm.output.token?.decimals,
  );

  console.log('swapExactTokensForETHWithPortfolios:params: ', {
    amountIn: inputAmount,
    amountOutMin: minAmountOut,
    path: swapForm.bestTrade.route.path,
    to: account,
    deadline: safeDateNowPlusEstimatedMinutes(+swapForm.settings.transactionDeadline),
    portfolios: swapForm.bestTrade.route.portfolios,
    destinationChain:
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.output.token?.chainId,
    firstXChainPortfolioNum:
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.bestTrade.route.crossChainPortfolioIndex,
  });

  return transactionWithEstimatedGas(routerContract, 'swapExactTokensForETHWithPortfolios', [
    inputAmount,
    minAmountOut,
    swapForm.bestTrade.route.path,
    account,
    safeDateNowPlusEstimatedMinutes(+swapForm.settings.transactionDeadline),
    swapForm.bestTrade.route.portfolios,
    +swapForm.bestTrade.route.crossChainPortfolioIndex === -1 ? 0 : swapForm.output.token?.chainId,
    +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
      ? 0
      : swapForm.bestTrade.route.crossChainPortfolioIndex,
  ]);
}

async function swapExactTokensForTokensWithPortfolios(
  swapForm: ISwapForm,
  account: string,
  routerContract: ethers.Contract,
): Promise<ethers.providers.TransactionResponse> {
  const inputAmount = safeParseUnits(
    swapForm.input.amount,
    swapForm.input.token?.decimals,
  ).toString();
  const minAmountOut = safeParseUnits(
    new BigNumber(swapForm.output.amount || 1)
      .div(new BigNumber(swapForm.settings.slippageTolerance).div(100).plus(1))
      .toString(),
    swapForm.output.token?.decimals,
  );

  console.log('swapExactTokensForTokensWithPortfolios:params: ', {
    amountIn: inputAmount,
    amountOutMin: minAmountOut,
    path: swapForm.bestTrade.route.path,
    to: account,
    deadline: safeDateNowPlusEstimatedMinutes(+swapForm.settings.transactionDeadline),
    portfolios: swapForm.bestTrade.route.portfolios,
    destinationChain:
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.output.token?.chainId,
    firstXChainPortfolioNum:
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.bestTrade.route.crossChainPortfolioIndex,
  });

  return transactionWithEstimatedGas(routerContract, 'swapExactTokensForTokensWithPortfolios', [
    inputAmount,
    minAmountOut,
    swapForm.bestTrade.route.path,
    account,
    safeDateNowPlusEstimatedMinutes(+swapForm.settings.transactionDeadline),
    swapForm.bestTrade.route.portfolios,
    +swapForm.bestTrade.route.crossChainPortfolioIndex === -1 ? 0 : swapForm.output.token?.chainId,
    +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
      ? 0
      : swapForm.bestTrade.route.crossChainPortfolioIndex,
  ]);
}

async function swapETHForExactTokensWithPortfolios(
  swapForm: ISwapForm,
  account: string,
  routerContract: ethers.Contract,
): Promise<ethers.providers.TransactionResponse> {
  const amountOut = safeParseUnits(
    swapForm.output.amount,
    swapForm.output.token?.decimals,
  ).toString();
  const maxAmountIn = safeParseUnits(
    new BigNumber(swapForm.input.amount || 0)
      .multipliedBy(new BigNumber(swapForm.settings.slippageTolerance).div(100).plus(1))
      .toString(),
    swapForm.input.token?.decimals,
  );
  const overrides = {
    value: maxAmountIn,
  };

  console.log('swapETHForExactTokensWithPortfolios:params: ', {
    amountOut: amountOut,
    path: swapForm.bestTrade.route.path,
    to: account,
    deadline: safeDateNowPlusEstimatedMinutes(+swapForm.settings.transactionDeadline),
    portfolios: swapForm.bestTrade.route.portfolios,
    destinationChain:
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.output.token?.chainId,
    firstXChainPortfolioNum:
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.bestTrade.route.crossChainPortfolioIndex,
    overrides: overrides,
  });

  return transactionWithEstimatedGas(
    routerContract,
    'swapETHForExactTokensWithPortfolios',
    [
      amountOut,
      swapForm.bestTrade.route.path,
      account,
      safeDateNowPlusEstimatedMinutes(+swapForm.settings.transactionDeadline),
      swapForm.bestTrade.route.portfolios,
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.output.token?.chainId,
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.bestTrade.route.crossChainPortfolioIndex,
    ],
    overrides,
  );
}

async function swapTokensForExactETHWithPortfolios(
  swapForm: ISwapForm,
  account: string,
  routerContract: ethers.Contract,
): Promise<ethers.providers.TransactionResponse> {
  const amountOut = safeParseUnits(
    swapForm.output.amount,
    swapForm.output.token?.decimals,
  ).toString();
  const maxAmountIn = safeParseUnits(
    new BigNumber(swapForm.input.amount || 0)
      .multipliedBy(new BigNumber(swapForm.settings.slippageTolerance).div(100).plus(1))
      .toString(),
    swapForm.input.token?.decimals,
  );

  console.log('swapTokensForExactETHWithPortfolios:params: ', {
    amountOut: amountOut,
    maxAmountIn: maxAmountIn,
    path: swapForm.bestTrade.route.path,
    to: account,
    deadline: safeDateNowPlusEstimatedMinutes(+swapForm.settings.transactionDeadline),
    portfolios: swapForm.bestTrade.route.portfolios,
    destinationChain:
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.output.token?.chainId,
    firstXChainPortfolioNum:
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.bestTrade.route.crossChainPortfolioIndex,
  });

  return transactionWithEstimatedGas(routerContract, 'swapTokensForExactETHWithPortfolios', [
    amountOut,
    maxAmountIn,
    swapForm.bestTrade.route.path,
    account,
    safeDateNowPlusEstimatedMinutes(+swapForm.settings.transactionDeadline),
    swapForm.bestTrade.route.portfolios,
    +swapForm.bestTrade.route.crossChainPortfolioIndex === -1 ? 0 : swapForm.output.token?.chainId,
    +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
      ? 0
      : swapForm.bestTrade.route.crossChainPortfolioIndex,
  ]);
}
async function swapTokensForExactTokensWithPortfolios(
  swapForm: ISwapForm,
  account: string,
  routerContract: ethers.Contract,
): Promise<ethers.providers.TransactionResponse> {
  const amountOut = safeParseUnits(
    swapForm.output.amount,
    swapForm.output.token?.decimals,
  ).toString();
  const maxAmountIn = safeParseUnits(
    new BigNumber(swapForm.input.amount || 0)
      .multipliedBy(new BigNumber(swapForm.settings.slippageTolerance).div(100).plus(1))
      .toString(),
    swapForm.input.token?.decimals,
  );

  console.log('swapTokensForExactTokensWithPortfolios:params: ', {
    amountOut: amountOut,
    maxAmountIn: maxAmountIn,
    path: swapForm.bestTrade.route.path,
    to: account,
    deadline: safeDateNowPlusEstimatedMinutes(+swapForm.settings.transactionDeadline),
    portfolios: swapForm.bestTrade.route.portfolios,
    destinationChain:
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.output.token?.chainId,
    firstXChainPortfolioNum:
      +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
        ? 0
        : swapForm.bestTrade.route.crossChainPortfolioIndex,
  });

  return transactionWithEstimatedGas(routerContract, 'swapTokensForExactTokensWithPortfolios', [
    amountOut,
    maxAmountIn,
    swapForm.bestTrade.route.path,
    account,
    safeDateNowPlusEstimatedMinutes(+swapForm.settings.transactionDeadline),
    swapForm.bestTrade.route.portfolios,
    +swapForm.bestTrade.route.crossChainPortfolioIndex === -1 ? 0 : swapForm.output.token?.chainId,
    +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
      ? 0
      : swapForm.bestTrade.route.crossChainPortfolioIndex,
  ]);
}
