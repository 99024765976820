<template>
  <Button
    v-if="!isHideButton()"
    class="button-base"
    :class="{
      'button-danger': isWalletError(),
    }"
  >
    <Icon
      :name="isWalletError() ? 'danger' : 'wallet'"
      size="20"
      class="mr-s1"
      :class="{ 'text-gray': !isWalletError() }"
    ></Icon>
    <div v-if="isWalletConnect" class="flex align-items-center">
      <div>
        {{ getWalletButton() }}
      </div>
    </div>
    <span v-else> {{ $t('wallet.connect') }}</span>
  </Button>
</template>

<script>
import Icon from '@/components/icon/Icon';
import { mapGetters } from 'vuex';
import { MODULE_NAMES } from '@/store';

export default {
  name: 'WalletButton',
  components: {
    Icon,
  },
  props: {
    isWalletConnect: Boolean,
  },
  computed: {
    ...mapGetters(MODULE_NAMES.WALLET, ['getWalletName', 'getWalletNetwork']),
  },
  methods: {
    isHideButton() {
      return this.isWalletConnect && this.$store.state.layout.isMobile;
    },
    getWalletButton() {
      return this.getWalletName.slice(0, 6) + '...' + this.getWalletName.slice(-4);
    },
    isWalletError() {
      return this.$store.state.wallet.isInjected && !this.$store.state.wallet.isNetworkSupported;
    },
  },
};
</script>

<style scoped></style>
