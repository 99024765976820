import { formatNumbersMixin } from '@/mixins/format-numbers.mixin';
import BigNumber from 'bignumber.js';

export const format = formatNumbersMixin.methods.format;

export function toDecimalPlacesOrLT(
  value: string | number | BigNumber | undefined | null,
  options: { decimalPlaces?: number; addUsdSymbol?: boolean } = {},
): string {
  if (value == null) {
    return '';
  }

  const decimalPlaces = options.decimalPlaces || 2;
  const symbolToInsert = options.addUsdSymbol ? '$' : '';

  const bnValue = new BigNumber(value);
  const limit = 10 ** -decimalPlaces;

  if (!bnValue.eq(0) && bnValue.lt(limit)) {
    return `< ${symbolToInsert}${limit}`;
  }

  return symbolToInsert + format(bnValue.decimalPlaces(decimalPlaces));
}
