<template>
  <div class="panel-content-container">
    <WalletConnectorList
      v-if="!isWalletData()"
      @onSelectWallet="togglePanel($event)"
      @noWallet="$emit('noWallet', $event)"
    />
    <WalletDataList v-else :walletData="walletData" @disconnectWallet="onDisconnectWallet" />
  </div>
</template>

<script>
import WalletConnectorList from './WalletConnectorList.vue';
import WalletDataList from './WalletDataList.vue';

export default {
  name: 'WalletPanel',
  components: {
    WalletDataList,
    WalletConnectorList,
  },
  props: {
    walletData: Object,
    togglePanel: Function,
  },
  methods: {
    isWalletData() {
      return !!this.walletData;
    },
    onChangeWallet() {
      this.isWallet = false;
    },
    onDisconnectWallet() {
      this.$emit('disconnectWallet');
    },
  },
};
</script>

<style scoped></style>
