import { ref } from 'vue';
import { INotification } from '@/store/modules/notifications/models/notification.interface';
import { defineStore } from 'pinia';
import {
  NOTIFICATION_LIFETIME_DEFAULT,
  NOTIFICATION_ID_SEPARATOR,
} from '@/store/modules/notifications/constants/NOTIFICATION_CONSTANTS';

export const notifications = ref<INotification[]>([]);

const getTransactionId = (notificationId: string) => {
  return notificationId.slice(0, notificationId.indexOf(NOTIFICATION_ID_SEPARATOR));
};
const getNotificationId = (transactionId: string, step?: number) => {
  return `${transactionId}${NOTIFICATION_ID_SEPARATOR}${step ?? '0'}`;
};

export const useNotifications = defineStore('notifications', () => {
  const removeNotification = (notificationId: string): void => {
    const index = notifications.value.findIndex(item => item.id === notificationId);
    if (index !== -1) notifications.value.splice(index, 1);
  };
  const removeNotificationGroup = (transactionId: string): void => {
    notifications.value = notifications.value.filter(item => !item.id.includes(transactionId));
  };
  const removeAllNotifications = (): void => {
    notifications.value = [];
  };

  const addNotification = (options: INotification): void => {
    const notificationId = getNotificationId(options.id, options.step?.current || 0);
    const index = notifications.value.findIndex(item => item.id === notificationId);
    index !== -1
      ? Object.assign(notifications.value[index], { ...options, ...{ id: notificationId } })
      : notifications.value.push({ ...options, ...{ id: notificationId } });
    console.log(notifications.value);
    if (options.life) {
      setTimeout(() => removeNotification(notificationId), NOTIFICATION_LIFETIME_DEFAULT);
    }
    if (
      options.step &&
      options.step.current === options.step.total &&
      options.status === 'success'
    ) {
      setTimeout(() => removeNotificationGroup(options.id), NOTIFICATION_LIFETIME_DEFAULT);
    }
  };

  return {
    addNotification,
    removeNotification,
    removeNotificationGroup,
    removeAllNotifications,
  };
});
