<template>
  <Button
    :label="$t('wallet.connect')"
    :class="buttonClass"
    @click="toggleWalletPanel($event)"
    v-if="buttonClass && buttonClass !== 'not-fixed'"
  />
  <WalletButton
    :class="{ 'mx-s3': $store.state.layout.isMobile }"
    :is-wallet-connect="$store.state.wallet.isInjected"
    @click="toggleWalletPanel($event)"
    v-else
  />
  <OverlayPanel ref="walletPanel" class="wallet-panel-multilevel" @show="fixOverlayPosition()">
    <WalletPanel
      :walletData="getWalletData()"
      @onSelectWallet="toggleWalletPanel($event)"
      :togglePanel="toggleWalletPanel"
      @disconnectWallet="onDialogOpen"
      @noWallet="onNoWalletDialog($event)"
    />
  </OverlayPanel>
  <Dialog v-model:visible="isShowDialog" :modal="true" :showHeader="false" dismissableMask>
    <DisconnectConfirmation :cancel="onDialogClose" :confirm="onConfirm" />
  </Dialog>
  <NoWalletModal
    v-model="isNotWallet"
    :name="notInstalledWallet.name"
    :link="notInstalledWallet.link"
    v-if="isNotWallet"
  />
  <div
    v-if="$store.state.wallet.isInjected && $store.state.layout.isMobile"
    class="wallet-panel-multilevel"
  >
    <WalletDataList :walletData="getWalletData()" @disconnectWallet="onDialogOpen" />
  </div>
</template>

<script>
import WalletPanel from './WalletPanel.vue';
import WalletButton from './WalletButton';
import WalletDataList from './WalletDataList';
import { mapActions, mapGetters } from 'vuex';
import { MODULE_NAMES } from '@/store';
import { WALLET_ACTION_TYPES } from '@/store/modules/wallet/wallet.module';
import DisconnectConfirmation from './DisconnectConfirmation';
import NoWalletModal from '@/components/NoWalletModal.vue';
import { HEADER_HEIGHT } from '@/constants/LAYOUT_PARAMS';

export default {
  name: 'Wallet',
  components: {
    WalletDataList,
    WalletButton,
    WalletPanel,
    DisconnectConfirmation,
    NoWalletModal,
  },
  props: {
    mainButton: Boolean,
    buttonClass: { type: String, default: null },
  },
  data() {
    return {
      isWalletPanelOpen: false,
      isShowDialog: false,
      isNotWallet: false,
      notInstalledWallet: {},
    };
  },
  computed: {
    ...mapGetters(MODULE_NAMES.WALLET, ['getWalletName', 'getWalletNetwork']),
  },
  methods: {
    ...mapActions(MODULE_NAMES.WALLET, {
      walletLogout: WALLET_ACTION_TYPES.WALLET_LOGOUT,
    }),
    toggleWalletPanel(event) {
      this.$refs.walletPanel.toggle(event);
    },
    getWalletData() {
      return this.$store.state.wallet.isInjected
        ? {
            name: this.getWalletName,
            networkName: this.getWalletNetwork?.shortName,
            isNetworkSupported: this.$store.state.wallet.isNetworkSupported,
          }
        : null;
    },
    onDialogOpen() {
      this.isShowDialog = true;
    },
    onDialogClose() {
      this.isShowDialog = false;
    },
    onConfirm() {
      this.walletLogout();
      this.onDialogClose();
    },
    onNoWalletDialog(event) {
      this.isNotWallet = true;
      this.notInstalledWallet = event;
    },
    fixOverlayPosition() {
      if (!this.buttonClass) {
        const el = document.querySelector('.wallet-panel-multilevel');
        if (el) {
          el.classList.add('fixed');
          el.style.top = `${HEADER_HEIGHT}px`;
        }
      }
    },
  },
};
</script>

<style scoped></style>
