<template>
  <div class="flex align-items-center">
    <div class="connector-icon mr-s2">
      <img :src="connector.icon" />
    </div>
    <div>{{ connector.name }}</div>
  </div>
</template>

<script>
export default {
  name: 'WalletConnector',
  props: {
    connector: Object,
  },
};
</script>

<style scoped lang="scss">
.connector-icon {
  width: 20px;
  height: 20px;
  img {
    max-width: 100%;
  }
}
</style>
